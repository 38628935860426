import { Routes } from '@angular/router';
import { HomeComponent } from './core/pages/home/home.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'seguro-auto',
    loadChildren: () =>
      import('./core/pages/car-insurance/car.routes').then(
        (r) => r.CAR_INSURANCE_ROUTES
      ),
  },
  {
    path: 'seguro-moto',
    loadChildren: () =>
      import('./core/pages/moto-insurance/moto.routes').then(
        (r) => r.MOTO_INSURANCE_ROUTES
      ),
  },
  {
    path: 'seguro-art',
    loadChildren: () =>
      import('./core/pages/art-insurance/art.routes').then(
        (r) => r.ART_INSURANCE_ROUTES
      ),
  },
  {
    path: 'seguro-flota',
    loadChildren: () =>
      import('./core/pages/flota-insurance/flota.routes').then(
        (r) => r.FLOTA_INSURANCE_ROUTES
      ),
  },
  {
    path: 'seguro-hogar',
    loadChildren: () =>
      import('./core/pages/house-insurance/house.routes').then(
        (r) => r.HOUSE_INSURANCE_ROUTES
      ),
  },
  {
    path: 'seguro-otros',
    loadChildren: () =>
      import('./core/pages/others-insurance/others.routes').then(
        (r) => r.OTHERS_INSURANCE_ROUTES
      ),
  },
  {
    path: 'atencion-cliente',
    loadChildren: () =>
      import('./core/pages/client-support/client-support.routes').then(
        (r) => r.CLIENT_SUPPORT_INSURANCE_ROUTES
      ),
  },
  {
    path: 'preguntas-frecuentes',
    loadComponent: () =>
      import('./core/pages/faq-page/faq-page.component').then(
        (c) => c.FaqPageComponent
      ),
  },
  {
    path: 'denunciar-accidente',
    loadChildren: () =>
      import('./core/pages/report-accident/report-accident.routes').then(
        (r) => r.REPORT_ACCIDENT_ROUTES
      ),
  },
  {
    path: 'contacto',
    loadComponent: () =>
      import('./core/pages/contact/contact.component').then(
        (c) => c.ContactComponent
      ),
  },
  {
    path: 'baja',
    loadChildren: () =>
      import('./core/pages/termination/termination.routes').then(
        (r) => r.TERMINATION_ROUTES
      ),
  },
  {
    path: 'libro-quejas',
    loadChildren: () =>
      import('./core/pages/complaints/complaints.routes').then(
        (r) => r.COMPLAINTS_ROUTES
      ),
  },
  {
    path: 'postulate',
    loadChildren: () =>
      import('./core/pages/work-with-us/work-with-us.routes').then(
        (r) => r.WORK_WITH_US_ROUTES
      ),
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];
