import { Component } from '@angular/core';
import { GOOGLE_REVIEWS } from '../../../../constants';

@Component({
  selector: 'app-reviews',
  standalone: true,
  imports: [],
  templateUrl: './reviews.component.html',
  styleUrl: './reviews.component.css',
})
export class ReviewsComponent {
  googleReviews = GOOGLE_REVIEWS;
}
