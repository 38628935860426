import { Component } from '@angular/core';
import { MainSectionComponent } from '../../home/main-section/main-section.component';
import { BranchesSectionComponent } from '../../home/branches-section/branches-section.component';
import { InsurancesComponent } from '../../home/insurances/insurances.component';
import { AsegurusComponent } from '../../home/asegurus/asegurus.component';
import { ReviewsComponent } from '../../home/reviews/reviews.component';
import { FaqComponent } from '../../home/faq/faq.component';
import { BlogComponent } from '../../home/blog/blog.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    MainSectionComponent,
    BranchesSectionComponent,
    InsurancesComponent,
    AsegurusComponent,
    ReviewsComponent,
    FaqComponent,
    BlogComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})
export class HomeComponent {}
