<section aria-label="Footer">
  <div class="container py-5">
    <div class="w-100 d-flex justify-content-between align-items-baseline flex-wrap">
      <div class="d-none d-lg-block">
        <img class="logo" src="../../../assets/img/logo-icon-white.svg" alt="logo" width="116" height="56">
        <p class="py-4 mt-1">Asegurus.com es una marca registrada de DLP Consultora de Seguros S.A. la cual desempeña
          las funciones de productor asesor de seguros otorgadas por Ley Nacional 22.400, bajo mátricula nacional Nro
          1380.
        </p>
      </div>
      <div>
        <h3>Explorar</h3>
        <ul class="p-0 py-4">
          <li class="my-3"><a class="nav-link" [routerLink]="'/'">Seguros</a></li>
          <li class="my-3"><a class="nav-link" href="https://blog.asegurus.com/" target="_blank">Blog</a></li>
          <li class="my-3"><a class="nav-link" [routerLink]="'postulate'">Postulate</a></li>
          <li class="my-3"><a class="nav-link" [routerLink]="'preguntas-frecuentes'">Preguntas frecuentes</a></li>
        </ul>
      </div>
      <div>
        <h3>Clientes</h3>
        <ul class="p-0 py-4">
          <li class="my-3"><a class="nav-link" [routerLink]="'atencion-cliente'">Atención al cliente</a></li>
          <li class="my-3"><a class="nav-link" [routerLink]="'denunciar-accidente'">Denunciar accidente</a></li>
          <li class="my-3"><a class="nav-link" [routerLink]="'baja'">Baja</a></li>
          <li class="my-3"><a class="nav-link" [routerLink]="'libro-quejas'">Libro de quejas</a></li>
        </ul>
      </div>
      <div>
        <h3>Contacto</h3>
        <ul class="p-0 py-0 py-lg-4 mt-3">
          <li class="mt-1">Dirección: Ciudad de la Paz 1965 Oficina 12 A - C.A.B.A - C.P. 1428</li>
          <li>Teléfono: 11 5949-7692</li>
          <li>Email: {{email}}</li>
          <li class="pt-3">
            <a href="https://www.instagram.com/asegurus/" target="_blank" rel="noopener noreferrer">
              <img src="../../../assets/img/instagram-logo.svg" alt="instagram-logo" width="36" height="36">
            </a>
          </li>
        </ul>
      </div>
    </div>
    <hr class="d-lg-none">
    <div class="d-lg-none">
      <p class="py-4 mt-1">Asegurus.com es una marca registrada de DLP Consultora de Seguros S.A. la cual desempeña
        las funciones de
        productos de asesor de seguros otorgadas por la Ley Nacional 22.400, bajo mátricula nacional Nro 1380.
      </p>
    </div>
    <div class="snn-section d-flex gap-5 flex-wrap">
      <div>
        <img src="../../../assets/img/snn-logo.png" alt="ssn-logo" width="103" height="59">
        <div>
          <a class="nav-link" href="https://www.argentina.gob.ar/superintendencia-de-seguros" target="_blank">
            ssn.gob.ar
          </a>
          <p>{{phoneNumber}}</p>
        </div>
      </div>
      <div>
        <img src="../../../assets/img/pdp-logo.png" alt="pdp-logo" width="130" height="62">
        <div>
          <a class="nav-link" href="../../../assets/files/politicas-de-privacidad.pdf" target="_blank"
            rel="noopener noreferrer">
            Política de privacidad
          </a>
        </div>
      </div>
      <a href="http://qr.afip.gob.ar/?qr=Vupilqqsqv0x4j3m9xn4uQ,," target="_blank" rel="noopener noreferrer">
        <img src="../../../assets/img/data-fiscal.png" alt="data-fiscal" width="57" height="78">
      </a>
    </div>
  </div>
</section>
