<section class="bg-white" aria-label="Asegurús">
  <div class="container p-0 d-flex flex-column flex-md-row flex-lg-row flex-wrap gap-lg-4 gap-md-3">
    <div class="container bg-white col text-start w-100 pt-5">
      <div class="semi-title-badget">ASEGURÚS</div>
      <h2 class="pt-3 pb-1 pb-md-5 pb-lg-5">¿Por qué elegirnos?</h2>
      <div class="py-3">
        <img class="my-3" src="../../../../assets/img/discount-icon.png" alt="discount-icon" width="40" height="40">
        <h3 class="py-2">Descuentos de hasta el 30%</h3>
        <p class="detail-text py-3">Ahorrá tiempo y dinero utilizando nuestro multicotizador online. Encontrá lo que más
          se ajusta a tus
          necesidades.</p>
      </div>
      <div class="py-3">
        <img class="my-3" src="../../../../assets/img/live-chat-icon.png" alt="live-chat-icon" width="40" height="40">
        <h3 class="py-2">Estamos siempre</h3>
        <p class="detail-text py-3">Encontranos a través de nuestras redes sociales, por teléfono y si además lo
          necesitas te asesoramos 24/7 vía
          Whatsapp.</p>
      </div>
      <div class="py-3">
        <img class="my-3" src="../../../../assets/img/star-icon.png" alt="star-icon" width="40" height="40">
        <h3 class="py-2">Servicio post venta de excelencia</h3>
        <p class="detail-text py-3">Durante toda la vigencia de tu póliza contas con nuestro asesoramiento y asistencia
          ante cualquier problema.
        </p>
      </div>
    </div>
    <div class="numbers-container col text-center d-flex flex-column justify-content-evenly align-content-center py-5 w-100">
      <div class="">
        <h3 class="number-title">24.000+</h3>
        <p class="number-description px-5">Consultas resultas de nuestros clientes en el año</p>
      </div>
      <div class="">
        <h3 class="number-title">9.000+</h3>
        <p class="number-description px-5">Clientes</p>
      </div>
      <div class="">
        <h3 class="number-title">4.7/5.0</h3>
        <p class="number-description px-5">Experiencia de nuestros clientes</p>
      </div>
    </div>
  </div>
  <div class="d-none d-sm-block w-100 pb-5 bg-body-tertiary"></div>
</section>
