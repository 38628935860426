<nav class="navbar navbar-expand-lg w-100">
  <div class="container-fluid px-0 mx-2 mx-lg-5">
    <a role="button" class="logo" [routerLink]="''" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
      <img src="../../../assets/img/logo.svg" alt="asegurus-logo" width="116" height="56">
    </a>
    <button class="navbar-toggler border-0" type="button" data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse links-container bg-body-tertiary" id="navbarSupportedContent">
      <ul
        class="navbar-nav mb-lg-0 mt-2 d-flex align-content-center justify-content-lg-between mobile-navbar bg-body-tertiary">
        <li class="nav-item mobile-menu">
          <a [routerLink]="'/'" class="nav-link link py-3 d-flex" role="button" aria-current="page"
            data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
            <img class="d-inline d-lg-none mobile-menu-icon mx-2" src="../../../assets/img/home-icon.png" alt="home">
            Inicio
          </a>
        </li>
        <li class="nav-item dropdown mobile-menu d-block">
          <a class="nav-link link py-3 d-flex w-100 row-cols-1 " role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            <img class="d-inline d-lg-none mobile-menu-icon mx-2" src="../../../assets/img/insurance-icon.png"
              alt="insurance">
            Seguros
            <i class="dropdown-icon"></i>
          </a>
          <ul class="dropdown-menu dropdown-menu-custom row row-cols-4 row-cols-lg-1 text-center text-lg-start">
            <li class="col d-flex justify-content-center d-lg-block p-lg-2 rounded-top-3">
              <a [routerLink]="'/seguro-auto'" class="nav-link d-flex flex-column flex-lg-row p-lg-1" role="button"
                aria-expanded="false" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                <img class="d-inline mobile-menu-icon mx-2" src="../../../assets/img/car-icon.png" alt="Autos">
                Autos
              </a>
            </li>
            <li class="col d-flex justify-content-center d-lg-block p-lg-2 border-top border-bottom bordery-0">
              <a [routerLink]="'/seguro-moto'" class="nav-link d-flex flex-column flex-lg-row p-lg-1" role="button"
                aria-expanded="false" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                <img class="d-inline mobile-menu-icon mx-2" src="../../../assets/img/moto-icon.png" alt="Motos">
                Motos
              </a>
            </li>
            <li class="col d-flex justify-content-center d-lg-block p-lg-2">
              <a [routerLink]="'/seguro-art'" class="nav-link d-flex flex-column flex-lg-row p-lg-1" role="button"
                aria-expanded="false" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                <img class="d-inline mobile-menu-icon mx-2" src="../../../assets/img/art-icon.png" alt="ART">
                ART
              </a>
            </li>
            <li class="col d-flex justify-content-center d-lg-block p-lg-2 border-top border-bottom bordery-0">
              <a [routerLink]="'/seguro-flota'" class="nav-link d-flex flex-column flex-lg-row p-lg-1" role="button"
                aria-expanded="false" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                <img class="d-inline mobile-menu-icon mx-2" src="../../../assets/img/cars-icon.png" alt="Flota">
                Flota
              </a>
            </li>
            <li class="col d-flex justify-content-center d-lg-block p-lg-2">
              <a [routerLink]="'/seguro-hogar'" class="nav-link d-flex flex-column flex-lg-row p-lg-1" role="button"
                aria-expanded="false" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                <img class="d-inline mobile-menu-icon mx-2" src="../../../assets/img/home2-icon.png" alt="Hogar">
                Hogar
              </a>
            </li>
            <li class="col d-flex justify-content-center d-lg-block p-lg-2 border-top bordery-0 rounded-bottom-3">
              <a [routerLink]="'/seguro-otros'" class="nav-link d-flex flex-column flex-lg-row p-lg-1" role="button"
                aria-expanded="false" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                <img class="d-inline mobile-menu-icon mx-2" src="../../../assets/img/policy-icon.png" alt="Otros">
                Otros
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item dropdown mobile-menu d-block">
          <a class="nav-link link py-3 d-flex w-100 row-cols-1" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            <img class="d-inline d-lg-none mobile-menu-icon mx-2" src="../../../assets/img/help-icon.png" alt="help">
            Ayuda
            <i class="dropdown-icon"></i>
          </a>
          <ul class="dropdown-menu dropdown-menu-custom row row-cols-1">
            <li class="p-0 rounded-top-3">
              <a [routerLink]="'/preguntas-frecuentes'" class="nav-link d-flex flex-column p-3" role="button" aria-expanded="false"
                data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                Preguntas frecuentes
              </a>
            </li>

            <li class="p-0 ">
              <a [routerLink]="'/atencion-cliente'"
                class="nav-link d-flex flex-column p-3 border-top border-bottom bordery-0" role="button"
                aria-expanded="false" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                Atención al cliente
              </a>
            </li>
            <li class="p-0 rounded-bottom-3">
              <a [routerLink]="'/denunciar-accidente'" class="nav-link d-flex flex-column p-3" role="button"
                aria-expanded="false" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                Denunciar un accidente
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item mobile-menu">
          <a [routerLink]="'/postulate'" class="nav-link link py-3 d-flex" role="button" data-bs-toggle="collapse"
            data-bs-target=".navbar-collapse.show">
            <img class="d-inline d-lg-none mobile-menu-icon mx-2" src="../../../assets/img/heart-icon.png" alt="apply">
            Postulate
          </a>
        </li>
        <li class="nav-item mobile-menu">
          <a href="https://blog.asegurus.com/" target="_blank" class="nav-link link py-3 d-flex" role="button">
            <img class="d-inline d-lg-none mobile-menu-icon mx-2" src="../../../assets/img/book-icon.svg" alt="blog">
            Blog
          </a>
        </li>
        <li class="nav-item mobile-menu">
          <a [routerLink]="'/contacto'" class="nav-link link py-3 d-flex" role="button" data-bs-toggle="collapse"
            data-bs-target=".navbar-collapse.show">
            <img class="d-inline d-lg-none mobile-menu-icon mx-2" src="../../../assets/img/bubble-chat-icon.png"
              alt="contact">
            Contacto
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
