<section aria-label="Contenido principal" class="py-5 px-1">
  <h1 class="main-text pt-3 text-center">
    <p class="text-bigger">Somos los gurús del seguro,</p>
    <p class="text-smaller">lo hacemos fácil para vos</p>
  </h1>
  <div class="d-flex justify-content-lg-between justify-content-center align-bottom pt-lg-5">
    <img class="main-images" src="../../../assets/img/main-left-image.png" alt="main-left-image" width="400"
      height="500">
    <div class="form p-3 d-flex flex-column justify-content-evenly">
      <h3 class="form-title">
        <div>
          Compará, elegí y ahorrá
        </div>
        <div>
          hasta un 30% en tu seguro
        </div>
      </h3>
      <select [(ngModel)]="insurance" class="form-select" aria-label="Seguros">
        <option value="seguro-auto">Autos</option>
        <option value="seguro-moto">Moto</option>
        <option value="seguro-art">ART</option>
        <option value="seguro-flota">Flota</option>
        <option value="seguro-hogar">Hogar</option>
        <option value="seguro-otros">Otros</option>
      </select>
      <button type="button" class="btn btn-primary form-stepper-button" (click)="quote()">Cotizar</button>
    </div>
    <img class="main-images" src="../../../assets/img/main-right-image.png" alt="main-right-image" width="400"
      height="500">
  </div>
</section>
