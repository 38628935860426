<section aria-label="Compañías"
  class="d-flex flex-column justify-content-center align-content-center py-5 text-center container">
  <h2 class="title pb-3">Las mejores compañías al mejor precio</h2>
  <p class="pt-3">
    Nos aseguramos de trabajar sólo con las aseguradoras de mayor prestigio del país.
  </p>
  <div class="mw-100 my-4 d-flex flex-row flex-wrap gap-4 justify-content-around align-items-center">
    <img width="103" height="30" src="../../../../assets/img/companies/rus.svg" alt="rus">
    <img width="120" height="50" src="../../../../assets/img/companies/sancor.svg" alt="sancor">
    <img width="104" height="22" src="../../../../assets/img/companies/san-cristobal.svg" alt="san-cristobal">
    <img width="120" height="50" src="../../../../assets/img/companies/mercantil.svg" alt="mercantil">
    <img width="104" height="29" src="../../../../assets/img/companies/galeno.svg" alt="galeno">
    <img width="105" height="35" src="../../../../assets/img/companies/experta.svg" alt="experta">
    <img width="104" height="34" src="../../../../assets/img/companies/provinicia.svg" alt="provinicia">
  </div>
</section>
