<app-whatsapp-icon />
<app-spinner />
<div>
  <header>
    <app-nav-bar />
  </header>
  <main>
    <router-outlet />
  </main>
  <footer>
    <app-footer />
  </footer>
</div>
