<section aria-label="Blog" class="container py-5">
  <div class="semi-title-badget">BLOG</div>
  <h2 class="pt-3 pb-1 pb-md-4 pb-lg-4">Conocé más</h2>
  <div class="py-3 post-container">
    @for (post of blogPosts; track $index) {
    <article class="post-card d-flex flex-column">
      <a class="nav-link" [href]="post.link" target="_blank" rel="noopener noreferrer">
        <img class="post-img" [ngSrc]="post.imgUrl" alt="'img-post'" loading="lazy" width="300" height="300">
        <div class="p-3">
          <h6 class="post-category">{{post.category}}</h6>
          <h4 class="post-title">{{post.title}}</h4>
        </div>
      </a>
    </article>
    }
  </div>
</section>
