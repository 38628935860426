import { NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { BLOG_POSTS } from '../../../../constants';

@Component({
  selector: 'app-blog',
  standalone: true,
  imports: [NgOptimizedImage],
  templateUrl: './blog.component.html',
  styleUrl: './blog.component.css',
})
export class BlogComponent {
  blogPosts = BLOG_POSTS;
}
