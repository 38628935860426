<section aria-label="Seguros" class="container py-5">
  <div class="semi-title-badget">SEGUROS</div>
  <h2 class="pt-2 py-lg-3 w-100">
    <span class="d-block description">Descubrí todo lo que podes</span>
    <span class="d-block description"> asegurar con nosotros</span>
  </h2>
  <div
    class="py-3 rounded-2 d-flex gap-2 flex-wrap align-content-center justify-content-around justify-content-lg-between justify-content-xl-between">
    <div class="p-5 text-center d-flex flex-column bg-white rounded-3 icon-box">
      <a [routerLink]="'seguro-auto'">
        <img class="bg-transparent" src="../../../../assets/img/icons-color/car.png" alt="car-icon" width="56"
          height="56">
      </a>
      <span class="py-1 bg-transparent icon-title">Autos</span>
    </div>
    <div class="p-5 text-center d-flex flex-column bg-white rounded-3 icon-box">
      <a [routerLink]="'seguro-moto'">
        <img class="bg-transparent" src="../../../../assets/img/icons-color/moto.png" alt="moto-icon" width="56"
          height="56">
      </a>
      <span class="py-1 bg-transparent icon-title">Motos</span>
    </div>
    <div class="p-5 text-center d-flex flex-column bg-white rounded-3 icon-box">
      <a [routerLink]="'seguro-art'">
        <img class="bg-transparent" src="../../../../assets/img/icons-color/art.png" alt="art-icon" width="56"
          height="56">
      </a>
      <span class="py-1 bg-transparent icon-title">ART</span>
    </div>
    <div class="p-5 text-center d-flex flex-column bg-white rounded-3 icon-box">
      <a [routerLink]="'seguro-hogar'">
        <img class="bg-transparent" src="../../../../assets/img/icons-color/home.png" alt="home-icon" width="56"
          height="56">
      </a>
      <span class="py-1 bg-transparent icon-title">Hogar</span>
    </div>
    <div class="p-5 text-center d-flex flex-column bg-white rounded-3 icon-box">
      <a [routerLink]="'seguro-flota'">
        <img class="bg-transparent" src="../../../../assets/img/icons-color/cars.png" alt="cars-icon" width="56"
          height="56">
      </a>
      <span class="py-1 bg-transparent icon-title">Flota</span>
    </div>
    <div class="p-5 text-center d-flex flex-column bg-white rounded-3 icon-box">
      <a [routerLink]="'seguro-otros'">
        <img class="bg-transparent" src="../../../../assets/img/icons-color/others.png" alt="others-icon" width="56"
          height="56">
      </a>
      <span class="py-1 bg-transparent icon-title">Otros</span>
    </div>
  </div>
</section>
