<section aria-label="Reviews" class="py-5 reviews-container">
  <div class="d-flex justify-content-center align-items-center">
    <h3 class="semi-title-badget">CLIENTES</h3>
  </div>
  <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      @for (review of googleReviews; track $index) {
      @if($first) {
      <div class="carousel-item active">
        <div class="d-block pt-5 pb-3 text-center">
          <img class="px-1" src="../../../../assets/img/star-review-icon.svg" alt="star-review-icon" width="26"
            height="26">
          <img class="px-1" src="../../../../assets/img/star-review-icon.svg" alt="star-review-icon" width="26"
            height="26">
          <img class="px-1" src="../../../../assets/img/star-review-icon.svg" alt="star-review-icon" width="26"
            height="26">
          <img class="px-1" src="../../../../assets/img/star-review-icon.svg" alt="star-review-icon" width="26"
            height="26">
          <img class="px-1" src="../../../../assets/img/star-review-icon.svg" alt="star-review-icon" width="26"
            height="26">
        </div>
        <p class="w-100 review-message">{{ review?.message}}</p>
        <p class="client py-3 w-100">{{ review?.author }}</p>
      </div>
      } @else {
      <div class="carousel-item review-message">
        <div class="d-block pt-5 pb-2 text-center">
          <img class="px-1" src="../../../../assets/img/star-review-icon.svg" alt="star-review-icon" width="26"
            height="26">
          <img class="px-1" src="../../../../assets/img/star-review-icon.svg" alt="star-review-icon" width="26"
            height="26">
          <img class="px-1" src="../../../../assets/img/star-review-icon.svg" alt="star-review-icon" width="26"
            height="26">
          <img class="px-1" src="../../../../assets/img/star-review-icon.svg" alt="star-review-icon" width="26"
            height="26">
          <img class="px-1" src="../../../../assets/img/star-review-icon.svg" alt="star-review-icon" width="26"
            height="26">
        </div>
        <p class="w-100">{{ review?.message}}</p>
        <p class="client py-3 w-100">{{ review?.author }}</p>
      </div>
      }
      }
    </div>
  </div>
</section>
